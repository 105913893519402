import { useState, useEffect } from "react";
import { useUser } from "../../../state/UserProvider";
import { useSettings } from "../../../state/SettingsProvider";
import { Form } from "antd";
import { Radio } from "antd-mobile";
import "./style.css";
import FormFooter from "../../../components/UI/FormFooter";
import * as S from "./styledFormComponents";
import { ReactComponent as BusIcon } from "../../../assets/img/MoneyWheel/money-bus-icon.svg";
import { ReactComponent as CarIcon } from "../../../assets/img/MoneyWheel/money-car-icon.svg";
import { Spacer, IconPlacer } from "./IconSpacerUtils.js";

export const HowManyVehiclesForm = ({
  onFinish,
  onFinishFailed,
  previousPage,
  isNewPage,
}) => {
  // Form is an antd component
  // useForm() is a React hook
  const [form1] = Form.useForm();
  const { currentUser } = useUser();
  const { wheelSettings } = useSettings();

  useEffect(() => {
    console.log("HowManyVehiclesForm:  wheelSettings.setup_step is ");
    console.log(wheelSettings.setup_step);
    let autosOwn = undefined;
    if (isNewPage()) {
      // The user has not answered the question yet.
      autosOwn = "";
    } else {
      autosOwn = currentUser.vehicles.length ? "yes" : "no";
    }
    const fields = { autosOwned: autosOwn };

    form1.setFieldsValue(fields);
    console.log("useEffect() in HowManyVehiclesForm");
    console.log(fields);
    // currentUser ->        vehicles : []
    switchIcon(fields.autosOwned);
  }, []);

  const [icon, setIcon] = useState();
  const switchIcon = (val) => {
    if (!val) {
      setIcon(<BusIcon className="categoryIcon" disabled />);
    } else if (val === "no") {
      setIcon(<BusIcon className="categoryIcon" />);
    } else {
      setIcon(<CarIcon className="categoryIcon" />);
    }
  };

  const selectedValue = Form.useWatch("autosOwned", form1);

  return (
    <div className={"form-wrapper"}>
      <Spacer />
      <IconPlacer>{icon}</IconPlacer>
      <S.FormQuestion>Do you own a vehicle?</S.FormQuestion>
      <S.FormQuestionHelp>
        This could be a car, truck, motorcycle, scooter, boat or plane that
        requires insurance.
      </S.FormQuestionHelp>
      <Form
        form={form1}
        layout="vertical"
        name="autosOwned"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Form.Item
          name="autosOwned"
          id="autos_autosOwned"
          label=""
          rules={[
            {
              required: true,
              message: "Please enter whether you own a vehicle.",
            },
          ]}
        >
          <Radio.Group
            onChange={(val) => {
              console.log("HowManyVehiclesForm: Radio.Group: val =", val);
              switchIcon(val);
            }}
          >
            <Radio
              value="yes"
              icon={(checked) => <S.RadioCircle />}
              block
              style={S.radioStyle}
              className={"mfo-radio"}
            >
              Yes
            </Radio>
            <Radio
              value="no"
              icon={(checked) => <S.RadioCircle />}
              block
              style={S.radioStyle}
              className={"mfo-radio"}
            >
              No
            </Radio>
          </Radio.Group>
        </Form.Item>
        <FormFooter
          onPrevButton={(e) => previousPage(e)}
          onNextButton={selectedValue ? "enabled" : "disabled"}
        />
      </Form>
    </div>
  );
};
