import styled from "styled-components";
import { Button } from "antd";

export const MAX_WIDTH = 440;

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: ${(props) =>
    props.justifyContent ? props.justifyContent : "flex-end"};
  height: 100svh;
  width: 90vw;
  @media (min-width: ${MAX_WIDTH}px) {
    width: ${MAX_WIDTH}px;
  }
  position: relative;
  z-index: 10;
  background-color: white;
`;

export const TitleText = styled.div`
  font-family: AccentFont;
  font-size: 36px;
  font-weight: 400;
  text-align: center;
`;

export const ReminderText = styled.div`
  font-family: PrimaryVariableFont;
  font-size: 16px;
  font-weight: 700;
  color: #666666;
  text-align: center;
`;

export const GreenLink = styled.a`
  color: #00af15;
  font-size: ${(props) => (props.size ? props.size : 12)}px;
  &:active {
    color: #00af15;
  }
`;

export const BigGreenButton = styled(Button)`
  font-family: AccentFont;
  font-size: 24px;
  width: 100%;
  height: 50px;
  background: #68cd74 !important;
  border-radius: 18px;
  margin-bottom: 1em;
  margin-top: 1em;
`;

export const SmallText = styled.div`
  font-family: PrimaryVariableFont;
  font-size: 12px;
  line-height: 13.2px;
  text-align: center;
  color: #b3b3b3;
`;
