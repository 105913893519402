import { useState, useEffect } from "react";
import { useUser } from "../../../state/UserProvider";
import { Form } from "antd";
import { Radio } from "antd-mobile";
import "./style.css";
import FormFooter from "../../../components/UI/FormFooter";
import CircleBackground from "../../../components/UI/BackgroundHalfCircle";
import * as S from "./styledFormComponents";
import { ReactComponent as MarriedIcon } from "../../../assets/img/MoneyWheel/money-couple-icon.svg";
import { ReactComponent as SinglePersonIcon } from "../../../assets/img/MoneyWheel/money-single-icon.svg";
import { Spacer, IconPlacer } from "./IconSpacerUtils.js";

export const SingleOrMarriedForm = ({
  onFinish,
  onFinishFailed,
  previousPage,
}) => {
  // Form is an antd component
  // useForm() is a React hook
  const [form1] = Form.useForm();
  const { currentUser } = useUser();

  useEffect(() => {
    const fields = { marital_status: currentUser.marital_status };
    form1.setFieldsValue(fields);
    console.log("useEffect() in SingleOrMarriedForm");
    console.log(fields);
    // currentUser ->        marital_status: 'single'
    switchIcon(fields.marital_status);
  }, []);

  const [icon, setIcon] = useState();
  const switchIcon = (val) => {
    if (!val || val === "unspecified") {
      setIcon(<SinglePersonIcon className="categoryIcon" disabled />);
    } else if (val === "single") {
      setIcon(<SinglePersonIcon className="categoryIcon" />);
    } else {
      setIcon(<MarriedIcon className="categoryIcon" />);
    }
  };

  const selectedValue = Form.useWatch("marital_status", form1);

  return (
    <div className={"form-wrapper"}>
      {/* <CircleBackground /> */}
      <Spacer />
      <IconPlacer>{icon}</IconPlacer>
      <S.FormQuestion>
        What’s your current <br />
        relationship status?
      </S.FormQuestion>
      <S.FormQuestionHelp>
        Select married or partnered if you share one or more bank or financial
        accounts.
      </S.FormQuestionHelp>
      <Form
        form={form1}
        layout="vertical"
        name="SingleorMarried"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Form.Item
          name="marital_status"
          label=""
          rules={[
            {
              required: true,
              message: "Please make a selection",
            },
          ]}
        >
          <Radio.Group
            onChange={(val) => {
              console.log("SingleOrMarriedForm: Radio.Group: val =", val);
              switchIcon(val);
            }}
          >
            <Radio
              value="single"
              icon={(checked) => <S.RadioCircle />}
              block
              style={S.radioStyle}
              className={"mfo-radio"}
            >
              Single
            </Radio>
            <Radio
              value="partnered"
              icon={(checked) => <S.RadioCircle />}
              block
              style={S.radioStyle}
              className={"mfo-radio"}
            >
              Partnered
            </Radio>
            <Radio
              value="married"
              icon={(checked) => <S.RadioCircle />}
              block
              style={S.radioStyle}
              className={"mfo-radio"}
            >
              Married
            </Radio>
          </Radio.Group>
        </Form.Item>
        <FormFooter
          onPrevButton={(e) => previousPage(e)}
          onNextButton={
            !selectedValue || selectedValue === "unspecified"
              ? "disabled"
              : "enabled"
          }
        />
      </Form>
    </div>
  );
};
