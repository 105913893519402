import { useRef } from "react";
import { Tour } from "antd";
import Tracker from "./Tracker";
import getCategoryIcons from "./GoalWheelIcons";
import "./style.css";

export default function GoalsWheel(props) {
  const childRefs = useRef(null);

  let steps = [];
  if (childRefs.current) {
    const { ref7, ref8, ref9 } = childRefs.current;

    steps = [
      {
        title: "Goals Wheel",
        description: "Your Goals Wheel shows ...",
        target: () => ref7.current,
      },
      {
        title: "Sample 8",
        description: "Description 8 goes here",
        target: () => ref8.current,
      },
      {
        title: "Sample 9",
        description: "Description 9 goes here",
        target: () => ref9.current,
      },
    ];
  }

  // set the default, if isTour prop is not passed to Wheel
  let isTour = props.isTour;
  if (isTour === undefined) {
    isTour = false;
  }
  console.log("GoalsWheel: isTour =", isTour);

  // start w/ N category, and go clockwise
  const categoryLabel = [
    ["Cash Flow", "Buffer"],
    ["Individual", "Goals"],
    ["Mid Term", "Goals"],
    ["Short Term", "Travel"],
    ["Other Short", "Term Goals"],
    ["Long Term", "Travel"],
    ["Long Term", "Goals"],
    ["Retirement"],
  ];

  return (
    <>
      <Tracker
        {...props}
        categoryIcons={getCategoryIcons(props.config)}
        categoryLabel={categoryLabel}
        ref={childRefs}
      />
      <Tour
        open={isTour}
        onClose={() => props.setIsTour(false)}
        steps={steps}
        disabledInteraction={true}
        closeIcon={false}
      />
    </>
  );
}
