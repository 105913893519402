import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useCheckResponseFail } from "../../hooks/useCheckResponseFail";
import backend from "../../functions/backend";
import ReplyControl from "../../components/Community/ReplyControl";
import { ErrorNoticeBar } from "../../components/UI/ErrorNoticeBar";
import MoneyFlowHeader from "../../components/PageComponents/MoneyFlowHeader";
import BottomNavBar from "../../components/PageComponents/BottomNavBar";

export default function Community() {
  const navigate = useNavigate();
  const [postList, setPostList] = useState([]);
  const [title, setTitle] = useState("");
  const { groupId } = useParams();
  const checkResponseFail = useCheckResponseFail();

  const getPosts = async () => {
    const response = await backend.get(
      "/v1/community/post?group_id=" + groupId,
    );
    if (checkResponseFail(response, "Failed to get posts:")) return;

    let postData = [];

    if (response.items) {
      console.log("Got " + response.items.length + " posts.");

      for (let i = 0; i < response.items.length; i++) {
        postData[i] = {};
        postData[i].id = response.items[i].id;
        postData[i].group_id = response.items[i].group_id;
        postData[i].title = response.items[i].title;
        postData[i].replies = response.items[i].replies;
      }
    }

    setPostList(postData);
  };

  useEffect(() => {
    getPosts();
  }, []);

  const addPost = async () => {
    const data = {
      group_id: groupId,
      title: title,
    };
    const response = await backend.post("/v1/community/post", data);
    checkResponseFail(response, "Failed to add post:");

    getPosts();
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log({ title });
    addPost();
    setTitle("");
  };

  const handleBack = () => {
    navigate("/community");
  };

  return (
    <div>
      <MoneyFlowHeader />
      <ErrorNoticeBar />
      <button onClick={() => handleBack()}>Back to Community</button>
      <h2>Group {groupId}</h2>
      <form onSubmit={handleSubmit}>
        <div>
          <label htmlFor="title">Title</label>
          <input
            type="text"
            name="title"
            required
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
          <button>Create New Post</button>
        </div>
      </form>
      <div>
        <h3>Posts</h3>
        {postList.map((post) => (
          <div key={post.id}>
            {post.title}
            <br />
            <ReplyControl
              numberOfReplies={post.replies.length}
              postId={post.id}
            />
            <br />
          </div>
        ))}
      </div>
      <BottomNavBar />
    </div>
  );
}
