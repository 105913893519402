import { useRef } from "react";
import { Tour } from "antd";
import Tracker from "./Tracker";
import getCategoryIcons from "./CreditWheelIcons";
import "./style.css";

export default function CreditWheel(props) {
  const childRefs = useRef(null);

  let steps = [];
  if (childRefs.current) {
    const { ref4, ref5, ref6 } = childRefs.current;

    steps = [
      {
        title: "Credit Tracker",
        description: "Your Credit Tracker shows ...",
        target: () => ref4.current,
      },
      {
        title: "Sample 2",
        description: "Description 2 goes here",
        target: () => ref5.current,
        placement: "top",
      },
      {
        title: "Sample 3",
        description: "Description 3 goes here",
        target: () => ref6.current,
      },
    ];
  }

  // set the default, if isTour prop is not passed to Tracker
  let isTour = props.isTour;
  if (isTour === undefined) {
    isTour = false;
  }
  console.log("CreditWheel: isTour =", isTour);

  // start w/ N category, and go clockwise
  const categoryLabel = [
    ["Real-Property", "Debt"],
    ["High Interest", "Debt"],
    ["Personal", "Property", "Debt"],
    ["Personal", "Debt /", "Loans"],
    ["Insurance", "Taxes"],
    ["Medical", "Debt"],
    ["Education /", "Travel Debt"],
    ["Credit Card", "Debt"],
  ];

  return (
    <>
      <Tracker
        {...props}
        categoryIcons={getCategoryIcons(props.config)}
        categoryLabel={categoryLabel}
        ref={childRefs}
      />
      <Tour
        open={isTour}
        onClose={() => props.setIsTour(false)}
        steps={steps}
        disabledInteraction={true}
        closeIcon={false}
      />
    </>
  );
}
