import { Form, Input, Button } from "antd";
import { useState } from "react";
import styled from "styled-components";

export default function CoolFormInput({
  name,
  message,
  placeholder,
  password = false,
  minMax = [0, 80],
}) {
  const [moveText, setMovetext] = useState(false);
  const handleInputChange = (e) => {
    if (e.target.value) {
      setMovetext(true);
    } else {
      setMovetext(false);
    }
  };
  return (
    <MainContainer>
      <SmallText>{moveText ? placeholder : ""}</SmallText>
      <Form.Item
        name={name}
        rules={[
          {
            required: true,
            message: message,
            max: minMax[1],
            min: minMax[0],
          },
        ]}
      >
        {password ? (
          <StyleInputPassword
            onChange={handleInputChange}
            placeholder={placeholder}
          />
        ) : (
          <StyleInput onChange={handleInputChange} placeholder={placeholder} />
        )}
      </Form.Item>
    </MainContainer>
  );
}
const MainContainer = styled.div`
  position: relative;
  margin-top: 35px;
`;
const SmallText = styled.div`
  position: absolute;
  top: -10px;
  left: 10px;
  z-index: 10;
  background-color: white;
  flex-grow: 0;
  display: inline-block;
  padding: 0 4px;
  color: #68cd74;
  font-size: 0.75rem;
  max-height: 21px;
`;
const StyleInput = styled(Input)`
  &:focus {
    border-color: #68cd74 !important;
  }
  border-color: #d9f7bd !important;
  height: 42px;
  border-radius: 12px;
`;
const StyleInputPassword = styled(Input.Password)`
  &.ant-input-affix-wrapper {
    border-color: #d9f7bd;
  }
  &.ant-input-affix-wrapper-focused {
    border-color: #68cd74;
  }
  height: 42px;
  border-radius: 12px;
`;
