import styled from "styled-components";

export default function CircleBackground(props) {
  return (
    <div>
      <ColoredSection gradient={props.gradient} />
      <CurvedSection />
    </div>
  );
}

const ColoredSection = styled.div`
  position: fixed;
  width: 100vw;
  height: 60vh;
  left: 0;
  top: 0;
  background: linear-gradient(
    to bottom,
    ${(props) => props.gradient[0]} 0%,
    ${(props) => props.gradient[1]} 100%
  );
  z-index: 0;
`;
const CurvedSection = styled.div`
  position: fixed;
  top: 16vh;
  left: 0vw;
  width: 100vw;
  height: 60vh;
  background-color: white;
  border-radius: 100% 100% 0 0 / 50% 50% 0 0;
  z-index: 0;
`;
