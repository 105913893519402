const Text_Below_Position_Angle_Map = {
  NW: 0.77,
  N: 0.5,
  NE: 0.22,
  E: -0.02,
  SE: -0.26,
  S: -0.5,
  SW: -0.735,
  W: 1.02,
};

const Text_Above_Position_Angle_Map = {
  NW: 0.75,
  N: 0.5,
  NE: 0.25,
  E: -0.02,
  SE: -0.27,
  S: -0.5,
  SW: -0.73,
  W: 1.02,
};

const Radius_Map = {
  NW: 126,
  N: 120,
  NE: 126,
  E: 135,
  SE: 137,
  S: 140,
  SW: 137,
  W: 135,
};

const Size_Map = {
  NW: 40,
  N: 49,
  NE: 40,
  E: 40,
  SE: 40,
  S: 40,
  SW: 40,
  W: 40,
};

export default function PlacedSVG(props) {
  // props are radius, angle, SVG, label, id, fontSize, fill, position
  const SVG = props.svg;
  //round the label to 2 decimal places
  const label = Math.round(props.label);

  //this calculation is based off of the viewport of the main SVg in the tracker file
  //it has width and height 390 and starts at 38,38
  const xCenter = 390 / 2 + 38;
  const yCenter = 390 / 2 + 38;

  const Position_Angle_Map = props.categoryLabel
    ? Text_Below_Position_Angle_Map
    : Text_Above_Position_Angle_Map;
  const angle = Position_Angle_Map[props.position] * Math.PI;

  let size = Size_Map[props.position];
  let radius = Radius_Map[props.position];

  const padding = 4;

  // Handle multi-line labels. Shrink icons and text labels.
  let categoryLabelLines; // each category can have 1 to 3 lines of text
  let categoryLabelFontSize = "15";
  // props.categoryLabel can be a string or an array with 1 to 3 string
  if (props.categoryLabel instanceof Array) {
    categoryLabelLines = [...props.categoryLabel];
    categoryLabelFontSize = "13";
    size -= 5;
    if (props.position === "N") {
      size = 35;
      radius = 126;
    }
  } else {
    categoryLabelLines = [props.categoryLabel];
  }

  const x = radius * Math.cos(angle) + xCenter;
  let y = yCenter - radius * Math.sin(angle);

  //because the text is underneath the icon in the expense review, shift the coordinates up a little
  if (props.categoryLabel) {
    y -= 25;
  }

  return (
    <g>
      <svg style={{ overflow: "visible" }}>
        {/* <rect x={x-size/2} y={y-size/2 -padding - 11.25} height={size} width={size} fill="pink" /> */}
        <g>
          <text
            x={x}
            y={y - size / 2 - padding}
            id={props.text_id}
            fontSize={"17"}
            fill={props.textFill ? props.textFill : "#596064"}
            textAnchor={"middle"}
            stroke={props.textFill ? props.textFill : "#596064"}
            strokeWidth={0}
          >
            <tspan fontSize={"12"} dy="-5">
              $
            </tspan>
            <tspan dy="5">{label}</tspan>
          </text>
        </g>

        <g id={props.idon_id}>
          <SVG
            x={x - size / 2}
            y={y - size / 2 + padding}
            height={size}
            width={size}
            fill={props.fill}
            stroke={props.fill}
          ></SVG>
        </g>
        <text
          x={x}
          y={y + size}
          id={props.text_id}
          fontSize={categoryLabelFontSize}
          fill={props.textFill ? props.textFill : "#596064"}
          textAnchor={"middle"}
          strokeWidth={0}
        >
          {categoryLabelLines[0]}
        </text>
        <text
          x={x}
          y={y + size + 15}
          id={props.text_id}
          fontSize={categoryLabelFontSize}
          fill={props.textFill ? props.textFill : "#596064"}
          textAnchor={"middle"}
          strokeWidth={0}
        >
          {categoryLabelLines[1]}
        </text>
        <text
          x={x}
          y={y + size + 30}
          id={props.text_id}
          fontSize={categoryLabelFontSize}
          fill={props.textFill ? props.textFill : "#596064"}
          textAnchor={"middle"}
          strokeWidth={0}
        >
          {categoryLabelLines[2]}
        </text>

        {/* <line x1={xCenter} y1={yCenter} x2={x} y2={y} stroke="red" /> */}
      </svg>
    </g>
  );
}
