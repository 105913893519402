import { daysSince } from "./timeDate";
import backend from "./backend";

// IN: lastReviewMonth: 1..12
// IN: month: 0..11
//
// return false if the month and year are the same as the last review
// because this means that we have already visited during this month and year
function alreadyShowedMonthReview(
  lastReviewMonth,
  lastReviewYear,
  month,
  year,
) {
  return (
    lastReviewMonth &&
    lastReviewMonth === month + 1 &&
    lastReviewYear &&
    lastReviewYear === year
  );
}

// IN: month: 0..11
// IN: year: 4 digit year (eg 2024)
// IN: alwaysShow: boolean.  While testing, make this true, to always show the End of Month Review
//
// returns the id that should be modified in the future if
// we need to review the previous month, otherwise returns -1
export async function getMonthReviewStatus(month, year, alwaysShow = false) {
  console.log(`getMonthReviewStatus(): month=${month} year=${year}`);
  const response = await backend.get("/v1/money_wheel_settings");
  if (response.items) {
    const item = response.items[0];
    if (alwaysShow) return item.id; // return the id, meaning we need to review the previous month.
    const createdDate = new Date(item.created_datetime);
    console.log(`createdDate month=${createdDate.getMonth()}`); // 0..11
    console.log(`createdDate year=${createdDate.getFullYear()}`);
    console.log(
      `last_review_month= ${item.last_review_month}   last_review_year= ${item.last_review_year}`,
    );
    if (
      alreadyShowedMonthReview(
        item.last_review_month,
        item.last_review_year,
        month,
        year,
      ) ||
      // or if the user account was created this month,
      (createdDate.getMonth() === month &&
        createdDate.getFullYear() === year) ||
      // or if account is less than 10 days old
      daysSince(createdDate) < 10
    ) {
      // then we won't need to review until next month
      return -1;
    }
    return item.id; //otherwise return the id, meaning we need to review the previous month,
    //id will be used to update the review status in the future
  }
  return -1; //default
}

// IN: month: 1..12
//
// tell server that user has seen the End of Month Review for month, year.
export async function updateMonthReview(month, year, id) {
  const response = await backend.patch("/v1/money_wheel_settings", {
    last_review_month: month, // 1..12
    last_review_year: year,
    id: id,
  });
  console.log("555");
  console.log(response);
}
