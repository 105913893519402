import { useState, useEffect } from "react";
import { Form } from "antd";
import { useNavigate } from "react-router-dom";
import { useDisplayErrorMsg } from "../../hooks/useDisplayErrorMsg";
import backend from "../../functions/backend";
import { ErrorNoticeBar } from "../../components/UI/ErrorNoticeBar";
import CoolFormInput from "../../components/UI/CoolFormInput";
import GreenGradientBackground from "../../components/UI/GreenGradientBackground";
import MoneyFlowFooter from "../../components/PageComponents/MoneyFlowFooter";

import { initUser, useUser } from "../../state/UserProvider";
import { initSettings, useSettings } from "../../state/SettingsProvider";
import { setMoneyFlowToken } from "../../state/stateApplication";
import { isGoodBrowser } from "../../functions/platform";
import { focusTextBoxes } from "../../functions/dom";

import styled from "styled-components";
import { SyncOutlined } from "@ant-design/icons";
import * as SP from "../../styles/styledPageComponents";
import {
  MainContainer,
  TitleText,
  ReminderText,
  GreenLink,
  BigGreenButton,
} from "../../styles/styledLoginComponents";

const MAX_WIDTH = 440; //used to help set the background image

export default function LoginContent() {
  const { dispatchUser } = useUser();
  const { dispatchSettings } = useSettings();

  const navigate = useNavigate();
  const { displayErrorMsg } = useDisplayErrorMsg();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    focusTextBoxes("basic_username");
    if (!isGoodBrowser()) {
      /*
      displayErrorMsg(
        "Early Access Note: Your specific device and browser combination has not yet been tested. Please let us know if you encounter any issues.",
      );
      */
    }
  }, []);

  const isPhoneNumber = (username) => {
    //phone number should be 10 digits long
    if (username.length !== 10) {
      return false;
    }
    const regex = /^[0-9]*$/;
    return regex.test(username);
  };

  const isEmail = (str) => {
    const regex = /^[-~+._0-9a-zA-Z]+@[-~+._0-9a-zA-Z]+\.[-~+._0-9a-zA-Z]+$/;
    return regex.test(str);
  };

  const onFinish = async (values) => {
    setIsLoading(true);
    console.log("Login values:", values);

    backend.tokenTimeout.reinitTimeout();
    let payload = {
      password: values.password,
    };
    if (isPhoneNumber(values.username)) {
      console.log("Logging in w/ phone number");
      payload.phone_number = values.username;
    } else if (isEmail(values.username)) {
      console.log("Logging in w/ email");
      payload.email_address = values.username;
    } else {
      console.log("Logging in w/ username");
      payload.username = values.username;
    }
    let data = await backend.post("/v1/user/login", payload);

    // Don't call checkResponseFail() here,
    // to avoid reloading this page and losing this error msg.
    if (!data.success) {
      console.warn("RESPONSE_ERR: Failed to POST user login:", data);
      displayErrorMsg(
        data.message + "  Please enter a valid username and password.",
      );
      setIsLoading(false);
      document.getElementsByTagName("HTML")[0].scrollTop = 0;
    }
    console.log("Response data: " + JSON.stringify(data));

    if (data.token) {
      // store the MF access token
      setMoneyFlowToken(data.token);

      let user3 = await initUser();

      console.log("LoginContent: onFinish(): user3", user3);

      dispatchUser({
        type: "USER_LOGIN",
        payload: { user: user3 },
      });

      let wheelSettings = await initSettings(user3);

      dispatchSettings({
        type: "SETTINGS_INIT",
        payload: { settings: wheelSettings },
      });

      // XXX TODO check user.type === "admin" as there will be multiple different admin users
      if (values["username"] === "adminuser" && data.success) {
        navigate(`/admin`, { replace: true });
      } else if (data.success) {
        let dataUserAgent = await backend.post("/v1/user/device", {
          name: window.navigator.userAgent,
        });
        console.log(`userAgent = ${dataUserAgent}`);

        //  "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/126.0.0.0 Safari/537.36"
        //    "Mozilla/5.0 (Windows NT 10.0; Win64; x64; rv:127.0) Gecko/20100101 Firefox/127.0"

        // On logging in, always go to Money wheel setup or active Money wheel or expense-review.
        // Never debt or goal wheels.
        if (wheelSettings.is_setup_complete) {
          navigate(`/money-wheel`, { replace: true });
        } else if (
          wheelSettings.setup_step ===
          wheelSettings.SETUP_STEP_EXPENSE_REVIEW_INFO
        ) {
          navigate("/expense-review-info");
        } else if (
          wheelSettings.setup_step ===
          wheelSettings.SETUP_STEP_EXPENSE_REVIEW_TOUR
        ) {
          navigate("/expense-review-tour");
        } else if (
          wheelSettings.setup_step ===
          wheelSettings.SETUP_STEP_EXPENSE_REVIEW_SORT
        ) {
          navigate("/expense-review"); // go to Initial Expense Review
        } else if (
          wheelSettings.setup_step === wheelSettings.SETUP_STEP_PLAID
        ) {
          navigate("/link-account"); // go to Plaid setup
        } else {
          navigate(`/money-wheel-setup`, { replace: true });
        }
      }
    } else {
      console.log("No token: " + JSON.stringify(data));
      navigate(`/`, { replace: true });
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed: " + JSON.stringify(errorInfo));
  };

  return (
    <MainContainer>
      <GreenGradientBackground />
      <ErrorNoticeBar />

      <Form
        style={{ maxWidth: 430, width: "100%" }}
        layout="vertical"
        name="basic"
        initialValues={{
          remember: true,
        }}
        onFinish={(values) => onFinish(values)}
        onFinishFailed={onFinishFailed}
      >
        <TitleText>Welcome back!</TitleText>
        <ReminderText style={{ marginBottom: "4em" }}>
          Sign In to your account
        </ReminderText>

        {isLoading && (
          <LoadingIcon>
            <SyncOutlined spin />
          </LoadingIcon>
        )}
        <SP.FormItemContainer>
          <CoolFormInput
            name="username"
            message="Please input your username!"
            placeholder="Username / Email"
          />
          <CoolFormInput
            name="password"
            message="Please input your password!"
            placeholder="Password"
            password={true}
          />

          <div style={{ marginBottom: "1em", textAlign: "end" }}>
            <GreenLink href="/recover-password" size={12}>
              Forgot password?
            </GreenLink>
          </div>

          <Form.Item>
            <BigGreenButton type="primary" htmlType="submit">
              Login
            </BigGreenButton>
          </Form.Item>

          <div style={{ marginBottom: "2em", textAlign: "center" }}>
            <p>
              Don't have an account?{" "}
              <span>
                <GreenLink href="/register" id="registerLink" size={14}>
                  Create Account
                </GreenLink>
              </span>
            </p>
          </div>
        </SP.FormItemContainer>
      </Form>
      <MoneyFlowFooter />
    </MainContainer>
  );
}

const LoadingIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0.5em;
  margin-bottom: 1em;
  font-size: 7rem;
`;
