import { Button, Modal, Form, Select } from "antd";

export default function DateChangerModal({
  form1,
  isOpen,
  setIsOpen,
  currentMonth,
  onChangeMonth,
  viewYear,
  onChangeYear,
  aYears,
  setDateToCurrent,
}) {
  function closeModal() {
    setIsOpen(false);
  }

  async function onFinish(values) {
    console.log("DateChangeModal: onFinish() has values:");
    console.log(values);
  }

  function onFinishFailed(errorInfo) {}

  return (
    <Modal
      title="Pick a Month"
      open={isOpen}
      onOk={closeModal}
      onCancel={closeModal}
    >
      <Form
        form={form1}
        name="monthChanger"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Form.Item name="month">
          <Select
            defaultValue={currentMonth}
            onChange={onChangeMonth}
            options={[
              { value: "Jan", label: "Jan" },
              { value: "Feb", label: "Feb" },
              { value: "Mar", label: "Mar" },
              { value: "Apr", label: "Apr" },
              { value: "May", label: "May" },
              { value: "Jun", label: "Jun" },
              { value: "Jul", label: "Jul" },
              { value: "Aug", label: "Aug" },
              { value: "Sep", label: "Sep" },
              { value: "Oct", label: "Oct" },
              { value: "Nov", label: "Nov" },
              { value: "Dec", label: "Dec" },
            ]}
          ></Select>
        </Form.Item>
        <Form.Item name="year">
          <Select
            defaultValue={viewYear}
            onChange={onChangeYear}
            options={aYears}
          ></Select>
        </Form.Item>
      </Form>
      <Button onClick={setDateToCurrent}>Set to Today</Button>
    </Modal>
  );
}
